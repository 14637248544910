import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { ApplyContext } from "../../context/applyContext";
import Table from "../../components/Finalized ranking/Table";

const AdminRanking = () => {
  const { applications } = useContext(ApplyContext);
  const [fixedApplications, setFixedApplications] = useState(null);

  useEffect(() => {
    const completedPosts = applications?.filter((e) => e.completed === true);
    const approvedPosts = completedPosts?.filter((e) => e.approved == true);
    const thisyeahapprovedPosts = approvedPosts?.filter((e) => e.year == 2025);
    const finalists = thisyeahapprovedPosts?.filter((e) => e.finalist == true);
    finalists?.map((e) => {
      if (e.finalgrades.length > 0) {
        return (e.avgfinalgrades =
          e.finalgrades
            ?.map((e) => e.grade)
            .reduce((partialSum, a) => partialSum + a, 0) /
          e.finalgrades.length);
      } else {
        return (e.avgfinalgrades = 0);
      }
    });
    const finalgrades = finalists?.map((e) => {
      e.finalgrades.forEach((element) => {
        console.log(element.user);
        if (element.user == "6785218908e7783505eaf03e") {
          e.robinRanking = element.grade;
        } else if (element.user == "656bde51c1e9464725f89b8e") {
          e.lottaRanking = element.grade;
        } else {
          e.martenRanking = element.grade;
        }
      });
    });
    setFixedApplications(finalists);
  }, [applications]);

  const columns = [
    // { label: "Id", accessor: "id", sortable: true, sortbyOrder: "asc" },
    {
      label: "Namn",
      accessor: "name",
      sortable: false,
    },
    { label: "Titel", accessor: "title", sortable: false },
    // { label: "Kategori", accessor: "cat", sortable: true },
    { label: "Robin", accessor: "robinRanking", sortable: false },
    { label: "Mårten", accessor: "martenRanking", sortable: false },
    { label: "Lotta", accessor: "lottaRanking", sortable: false },

    {
      label: "Medel Ranking",
      accessor: "avgfinalgrades",
      sortable: true,

      sortbyOrder: "asc",
    },
  ];

  return (
    <>
      <div style={{ marginBottom: "100px" }}>
        {fixedApplications ? (
          <>
            <Table caption="" data={fixedApplications} columns={columns} />
          </>
        ) : (
          <h1>Laddar...</h1>
        )}
      </div>
    </>
  );
};

export default AdminRanking;
